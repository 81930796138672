import {CartService} from './CartService';
import {StyleSettingsService} from './StyleSettingsService';
import {StoreSettingsService} from './StoreSettingsService';
import {PickupMethod} from '@wix/wixstores-client-storefront-sdk';
import {ShippingOptionModel} from '../models/ShippingOption.model';

export type ShippingRuleOptionModel = ShippingOptionModel & {isPickup: boolean};

export class OrderService {
  private readonly cartService: CartService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly storeSettingsService: StoreSettingsService;
  public destinationWasChanged: boolean = false;

  constructor({
    cartService,
    styleSettingsService,
    storeSettingsService,
  }: {
    cartService: CartService;
    styleSettingsService: StyleSettingsService;
    storeSettingsService: StoreSettingsService;
  }) {
    this.cartService = cartService;
    this.styleSettingsService = styleSettingsService;
    this.storeSettingsService = storeSettingsService;
  }

  public get canShipToDestination() {
    return this.cartService.estimatedTotals?.shippingInfo?.canShipToDestination;
  }

  public get hasShippingRules(): boolean {
    return !!this.cartService.estimatedTotals?.shippingInfo?.shippingRule?.shippingOptions.length;
  }

  public get canShip() {
    /* istanbul ignore next */
    return (
      this.hasShippingRules ||
      //todo(guy segev): test delivery error for digital (cart.errors.cant_sell_to_country?)
      /* istanbul ignore next */
      this.cartService.isNonShippableCart ||
      /* istanbul ignore next */
      !this.destinationWasChanged
    );
  }

  public static toShippingRuleOptionModel(option: ShippingOptionModel): ShippingRuleOptionModel {
    return {
      ...option,
      isPickup: !!option.logistics?.pickupDetails?.address?.addressLine,
    };
  }

  public isPickup(): boolean {
    const selectedShippingRule = this.getSelectedShippingRule();
    return selectedShippingRule?.isPickup;
  }

  public hasTimeSlots(): boolean {
    const selectedShippingRule = this.getSelectedShippingRule();
    return Boolean(selectedShippingRule?.logistics.deliveryTimeSlot);
  }

  public get isPickupPoint(): boolean {
    const selectedShippingRule = this.getSelectedShippingRule();
    return selectedShippingRule?.logistics.pickupDetails?.pickupMethod === PickupMethod.PICKUP_POINT;
  }

  private getSelectedShippingRule(): ShippingRuleOptionModel {
    const selectedShippingRuleId = this.cartService.estimatedTotals?.shippingInfo?.selectedCarrierServiceOptionCode;

    return (
      selectedShippingRuleId &&
      /* istanbul ignore next - can't ignore the optional chaining :( */
      this.cartService.estimatedTotals?.shippingInfo.shippingRule?.shippingOptions
        .map(OrderService.toShippingRuleOptionModel)
        .find((option) => option.id === selectedShippingRuleId)
    );
  }

  public get shippingRuleOptions(): ShippingRuleOptionModel[] {
    if (!this.cartService.estimatedTotals?.shippingInfo?.shippingRule?.shippingOptions) {
      return [];
    }

    return this.cartService.estimatedTotals?.shippingInfo?.shippingRule.shippingOptions.map(
      OrderService.toShippingRuleOptionModel
    );
  }

  public get hasPickupOption(): boolean {
    return this.shippingRuleOptions.some((option) => option.isPickup);
  }

  public get hasEstimatedDestination(): boolean {
    return !!this.cartService.cartModel?.contactInfo?.address.country;
  }

  public get isEstimatedDestinationIsShippable() {
    return (
      this.hasEstimatedDestination &&
      (this.hasShippingRules || this.cartService.isNonShippableCart || this.destinationWasChanged)
    );
  }

  public get taxOnProduct() {
    return this.storeSettingsService.getFetchedData().taxOnProduct;
  }

  public get shouldDisplayTax() {
    const {shouldShowTax} = this.styleSettingsService;

    return (
      shouldShowTax &&
      !this.taxOnProduct &&
      (!this.cartService.isNonShippableCart || this.isEstimatedDestinationIsShippable)
    );
  }

  public get minimumOrderAmount() {
    return this.cartService.estimatedTotals.minimumOrderAmount;
  }

  public get hasShippingDestination() {
    return this.hasEstimatedDestination && (this.canShipToDestination || this.destinationWasChanged);
  }
}
